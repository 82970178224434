<template lang="pug">
  .narrative-schema-presets-page
    .header.app-header
      h1.nio-h1.text-primary-darker Narrative Schema Presets
    .app-loading(v-if="loading")
      v-progress-circular.progress(size="80" indeterminate color="#1438F5")
    .schema-presets(v-else-if="schemaPresets && attributes")
      SchemaPresetList(
        :attributes="attributes"
        :schema-presets="schemaPresets"
      )
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { replacePropertyRefs } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import SchemaPresetList from '@/shared/components/SchemaPresetList'
import { getAttributes, getSchemaPresets } from '@/shared/fetchOperations'

export default { 
  components: { SchemaPresetList },
  data: () => ({
    loading: true,
    schemaPresets: null,
    attributes: null
  }),
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    async openApiInit() {
      const [attributes, schemaPresets] = await Promise.all([getAttributes(this.$nioOpenApi), getSchemaPresets(this.$nioOpenApi)])
      const rawAttributes = [...attributes]
      this.attributes = attributes.map(attribute => {
        return {
          ...replacePropertyRefs(attribute, rawAttributes)
        }
      })
      this.loading = false
      this.schemaPresets = schemaPresets.filter(schemaPreset => schemaPreset.scope === 'public')
    }
  }
}
</script>

<style lang="sass">

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.narrative-schema-presets-page
  background-color: $c-white
  padding: 1.5rem
  .header
    display: flex
    justify-content: center
    align-items: flex-end
    position: relative
    margin-bottom: 2.2rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem
</style>
