<template lang="pug">
  .schema-preset-list
    NioSlatTable(
      v-if="tableColumns && schemaPresets"
      :items="schemaPresets"
      :columns="tableColumns"
      :search-config="searchOptions"
      :min-chars-to-search="2"
      :searchable-props="['name', 'description']"
      :single-select="selectable ? true : false"
      :initial-items-per-page="10"
      action="expand"
      search-header
      custom-slat-cell
      @itemClicked="getAttributes($event)"
      @paginationPageChanged="pageChanged"
    )
      template(v-slot:custom-slat-cell="slotProps")
        .header-summary
          .summary-content
            .nio-slat-image
              NioImageTile(
                :src="slotProps.item.icon ? slotProps.item.icon : 'https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg'"
                :img-background="slotProps.item.display_color"
              )
            .nio-slat-title-subtitle
              .nio-slat-title.nio-h4.text-primary-darker.nio-bold  {{ slotProps.item.name }}
              .nio-slat-subtitle.nio-p.text-primary-dark(v-if="slotProps.item.description") {{ slotProps.item.description}}
          .summary-action
            NioButton(
              icon-name="utility-plus"
              normal-secondary-append 
              @click="newSubscription(slotProps.item.id)" 
            ) New Subscription
          
      template(v-slot:item-expanded="slotProps")
        .display-row.root.display-table
          .display-column.root.description
            .preset-heading
              .nio-h6.text-primary-darker Included Attributes
            NioSchema(
              :attributes="schemaAttributes[slotProps.item.id]"
              :disable-interactions="true"
            )
            .delete-preset
              NioButton(
                v-if="allowDeactivate"
                caution-text 
                @click="deactivatePreset(slotProps.item.id)"
              ) Delete Preset
              
</template>

<script>

import { makeSelectedFromSchemaPreset } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

export default {
  props: {
    "attributes": { type: Array, required: true },
    "schemaPresets": { type: Array, required: true },
    "selectable": { type: Boolean, required: false, default: false },
    "allowDeactivate": { type: Boolean, required: false, default: false }
  },
  data: () => ({
    tableColumns: null,
    searchOptions: {
      findAllMatches: true,
      threshold: 0.45
    },
    schemaAttributes: {}
  }),	
  mounted() {
    this.tableColumns = [
      
    ]
  },
  methods: {
    getAttributes(schemaPreset) {
      const attributes = []
      schemaPreset.details.attributes.map(attribute => {
        attributes.push(this.attributes.find(rawAttribute => attribute.attribute_id === rawAttribute.id))
      })
      this.schemaAttributes[schemaPreset.id] = makeSelectedFromSchemaPreset(attributes, schemaPreset)
    },
    newSubscription(schemaPresetId) {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      parent.postMessage({
        name: 'pageNavigation',
        payload: `new/${schemaPresetId}`
      },"*")
    },
    pageChanged() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 80
        }
      },"*")
    },
    deactivatePreset(presetId) {
      this.$emit('deactivatePreset', presetId)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.schema-preset-list
  margin-top: 1.5rem
  .display-row
    margin-bottom: 1.5rem
  .preset-heading
    display: flex
    justify-content: space-between
    align-items: center
  ::v-deep .expanded-row
    border-bottom: 0.0625rem solid $c-primary-lighter
    position: relative
    padding: 1rem 1.5rem 1rem 1.5rem
    background-color: $c-canvas
  ::v-deep .expand-custom-action
    svg
      font-size: 0.875rem !important
  .delete-preset
    width: 100%
    margin-top: 1rem
    display: flex
    justify-content: flex-end
    align-items: center
  ::v-deep .slat-cell
    .nio-slat-title, .nio-slat-subtitle
      white-space: pre-wrap
      overflow: hidden
      max-width: 46.875rem
    .nio-slat-image
      align-self: flex-start
  ::v-deep tr.expanded
    background-color: $c-canvas !important
  ::v-deep 
    .custom-slat-cell
      height: 97px
      padding-left: 24px !important
      .header-summary // TODO refactor into mixin
        display: flex
        align-items: center
        justify-content: space-between
        .summary-content
          display: flex
          align-items: center
          flex-grow: 2
          .nio-slat-image 
            width: 64px !important
            height: 64px !important
            margin-right: 1rem  
            width: 3rem
            height: 3rem 
            justify-content: center
            align-items: center
            display: flex
            background-color: $c-canvas
            border-radius: 0.25rem            
          .nio-slat-title-subtitle
            display: flex
            flex-direction: column
            justify-content: center
            .nio-slat-title
              white-space: nowrap
              max-width: 550px
            .nio-slat-subtitle
              white-space: nowrap
              margin-top: 0.25rem
              max-width: 550px
        .summary-action
          
  </style>